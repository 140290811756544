
import { defineComponent } from "vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import "viewerjs/dist/viewer.css";
import { ErrorMessage, Field, Form } from "vee-validate";
import $ from "jquery";
import * as Yup from "yup";
import "viewerjs/dist/viewer.css";

export default defineComponent({
  name: "personal-accident-claims-details-component",
  components: {
    ErrorMessage,
    Field,
    Form,
    GoBackButton,
  },
  props: {
    widgetClasses: String,
    // publicId: String,
  },
  data() {
    return {
      hprClass: "col-xl-4",
      displayRemarkForm: false,
      claim: {},
      userType: {
        role: "",
      },
      claimRemarks: [{ remarks: "" }],
      clientUser: "",
      brokerUser: "",
      adminUser: "",
      underwriterUser: "",
      claimNumber: "",
      imageURL: "",
      deleteRoute: "",
      route: "",
      publicId: this.$route.params.publicId,
    };
  },
  setup() {
    const claimNumberValidator = Yup.object().shape({
      claimNumber: Yup.string()
        .required()
        .label("Claim number"),
    });

    const remarksValidator = Yup.object().shape({
      claimRemarks: Yup.array()
        .of(
          Yup.object().shape({
            remarks: Yup.string()
              .required()
              .min(6)
              .label("Remark"),
          })
        )
        .strict(),
    });

    return { claimNumberValidator, remarksValidator };
  },
  created() {
    const router = useRouter();
    const store = useStore();
    this.userType = store.getters.currentUser;
    this.clientUser = variables.INDIVIDUAL_CLIENT_USER_ROLE;
    this.brokerUser = variables.BROKER_USER_ROLE;
    this.adminUser = variables.SUPER_ADMIN_USER_ROLE;
    this.underwriterUser = variables.UNDERWRITER_USER_ROLE;

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }
    // Claim images route
    this.imageURL = variables.POLICY_IMAGES_ROUTE;

    // Get claim information route based on user roles
    if (this.userType.role == variables.INDIVIDUAL_CLIENT_USER_ROLE) {
      this.route = `${variables.CLAIMS_ROUTE}/${this.publicId}`;
    } else if (this.userType.role == variables.BROKER_USER_ROLE) {
      // Broker role claim information route
      this.route = `${variables.BROKER_CLIENTS_CLAIMS_ROUTE}/${this.publicId}`;
      this.deleteRoute = `${variables.BROKER_CLIENTS_CLAIMS_ROUTE}`;
    } else if (this.userType.role == variables.UNDERWRITER_USER_ROLE) {
      // Underwriter role claim information route
      this.route = `${variables.UNDERWRITERS_CLIENT_CLAIMS_ROUTE}/${this.publicId}`;
    } else if (
      this.userType.role == variables.ADMIN_USER_ROLE ||
      this.userType.role == variables.SUPER_ADMIN_USER_ROLE
    ) {
      // Super Admin & Admin role claim information route
      this.route = `${variables.SUPERADMIN_CLIENT_CLAIMS_ROUTE}/${this.publicId}`;
      this.deleteRoute = `${variables.BROKER_CLIENTS_CLAIMS_ROUTE}`;
    }

    // Get claim information
    this.getClaimInformation();
  },
  methods: {
    getClaimInformation() {
      const router = useRouter();
      //Fetch vehicle claim vehicle information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(this.route)
          .then(({ data }) => {
            //Assign data to variables
            this.claim = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
            router.go(-1);
          });
      }
    },
    capitalizeWord(value) {
      return variables.capitalizeWord(value);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addDynamicField() {
      this.claimRemarks.push({
        remarks: "",
      });
    },
    removeDynamicField(counter) {
      $(".remove-" + counter).remove();
    },
    submitClaimNumberForm(values, { resetForm }) {
      const submitButton1 = document.getElementById("kt_claim_number_submit");

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.removeAttribute("data-kt-indicator");

          // Update claim number
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(`${this.route}/number`, values)
              .then(() => {
                // variables.toastAlert(response.data.data.message, "success");
                variables.toastAlert(
                  "Claim number created successfully",
                  "success"
                );
                resetForm();
                this.getClaimInformation();
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    },
    submitRemarks(values, { resetForm }) {
      const submitButton2 = document.getElementById("kt_remarks_submit");

      if (submitButton2) {
        // Activate indicator
        submitButton2.setAttribute("data-kt-indicator", "on");
        const formData = new FormData();

        setTimeout(() => {
          submitButton2.removeAttribute("data-kt-indicator");

          for (const [, value] of Object.entries(values["remarks"])) {
            if (Array.isArray(value)) {
              value.forEach((itemValue, index) => {
                formData.append(`remarks[${index}]`, itemValue);
              });
            }
          }

          // Update claim number
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(`${this.route}/remarks`, values)
              .then(() => {
                variables.toastAlert(
                  "Claim remarks created successfully",
                  "success"
                );
                resetForm();
                this.getClaimInformation();
              })
              .catch(function() {
                // variables.toastAlert(error.response.data.error, "error");
                variables.toastAlert("Remark field cannot be empty", "error");
              });
          }
        }, 2000);
      }
    },
    activateClaim(publicId) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.post(`${this.route}/activate`, publicId)
          .then(({ data }) => {
            variables.toastAlert(data.data, "success");
            this.getClaimInformation();
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/no-photo-available.png";
    },
    downloadClaimDocument(id, uniqueFileName) {
      const submitButton1 = document.getElementById(id);

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        // Delete cart item
        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.setResponseType("blob");
          ApiService.get(
            `${variables.CLAIMS_ROUTE}/${this.publicId}/document/${uniqueFileName}`
          )
            .then((response) => {
              submitButton1.removeAttribute("data-kt-indicator");
              // console.log(response.data);
              const blob = new Blob([response.data], {
                type: "application/octet-stream",
              });

              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = uniqueFileName;
              // link.download = `${fileName}.${extension}`;

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch(function(error) {
              submitButton1.removeAttribute("data-kt-indicator");
              console.log(error);
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      }
    },
    deleteClaimImage(publicId) {
      // Delete claim image
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.delete(`${variables.CLAIMS_ROUTE}/image/${publicId}`)
          .then(() => {
            this.getClaimInformation();
            variables.toastAlert("Image deleted successfully", "success");
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    deleteClaimDocument(publicId) {
      // Delete claim document
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.delete(`${variables.CLAIMS_ROUTE}/document/${publicId}`)
          .then(() => {
            this.getClaimInformation();
            variables.toastAlert("Document deleted successfully", "success");
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    verifyClaimImage(publicId) {
      // Veridy claim image
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.post(`/broker/claim-image/${publicId}/verify`)
          .then(() => {
            this.getClaimInformation();
            variables.toastAlert(
              "Claim image verified successfully",
              "success"
            );
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    verifyClaimDocument(publicId) {
      // Veridy claim document
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.post(`/broker/claim-document/${publicId}/verify`)
          .then(() => {
            this.getClaimInformation();
            variables.toastAlert(
              "Claim document verified successfully",
              "success"
            );
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
