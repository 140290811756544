
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import PersonalAccidentClaimDetails from "@/components/reusable/claims/house-insurance/personal-accident/PersonalAccidentClaimDetails.vue";

export default defineComponent({
  name: "personal-accident-house-insurance-claims-details",
  components: {
    PersonalAccidentClaimDetails
  },
  props: ["publicId"],
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Claim Details", [
        "Claims",
        "House Insurance",
        "Personal Accident"
      ]);
    });
  }
});
